import React from 'react';

// import social data
import { social } from '../data';

// import logo
import Logo from '../assets/img/markatiq logo (color).svg';

const Footer = () => {
  const handleClick = () => {
    return document.getElementById('about').scrollIntoView();
  };
  return (
    <footer className='bottom-0 bg-tertiary h-32 lg:p-0 flex  items-center lg:mt-0 mt-[5rem] mb-[1rem]'>
      <div className='container mx-auto h-full flex flex-col lg:space-y-0 items-center justify-center gap-5 w-full'>
        {social.map((item, index) => {
          const { href, icon } = item;
          return;
          // <a
          //   className='text-accent lg:text-3xl text-[25px] hover:text-cyan-400'
          //   href={href}
          //   key={index}
          // >
          //   {icon}
          // </a>
        })}
        <div className='lg:w-20 lg:h-20  w-14 h-14'>
          <img
            src={Logo}
            alt=''
            className='cursor-pointer'
            onClick={handleClick}
          />
        </div>
        <p className='text-paragraph opacity-80 lg:text-[16px] text-[12px] text-center'>
          &copy; 2023 Made By ↓ <br />
          <a
            href='https://wa.me/9647728887281'
            className='underline underline-offset-4 hover:text-cyan-400'
          >
            Abdulmajeed Kutaibani
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
