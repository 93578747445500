import React from 'react';
import { ChipIcon } from '@heroicons/react/solid';
import { FaReact, FaGitSquare } from 'react-icons/fa';
import {
  SiTailwindcss,
  SiJavascript,
  SiFirebase,
  SiRedux,
  SiFramer,
} from 'react-icons/si';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
// import Brackets from './images/brackets.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const Skills = () => {
  defineElement(lottie.loadAnimation);
  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      id='skills'
      className=' lg:px-12 w-full lg:pb-24 min-h-screen relative overflow-hidden lg:mb-0 pb-36'
    >
      <div className=' lg:px-5 px-3  lg:py-10 py-5 mx-auto '></div>
      {/* //////////////////////////////////////// */}

      <div className='flex flex-wrap   lg:justify-evenly justify-around items-center w-11/12 mx-auto lg:w-full'>
        <div
          data-aos='fade-up-left'
          data-aos-delay='150'
          data-aos-duration='1000'
          data-aos-once='true'
          className=' flex flex-col justify-center items-center w-96  md:w-5/12 max-w-sm  lg:w-1/3 mb-6 rounded-xl   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005] border border-dotted border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/zpxybbhl.json'
              trigger='hover'
              style={{ width: '125px', height: '125px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-6 text-xl font-Tourney font-bold'>
            Web Development
          </h4>
          <p className='text-2xl w-11/12 p-3  font-Dongle font-normal leading-6'>
            Having a solid foundation of web development basics and the ability
            to build an elegant responsive website from scratch and write easy
            to understant code.
          </p>
          <div className='w-full min-h-5 h-5 bg-accent mt-auto rounded-b-lg'></div>
        </div>
        <div
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-once='true'
          data-aos-delay='400'
          className=' flex flex-col justify-center items-center w-96  md:w-5/12 max-w-sm  lg:w-1/3 mb-6 rounded-xl   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005] border border-dotted border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/zpxybbhl.json'
              trigger='hover'
              style={{ width: '125px', height: '125px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-6 text-xl font-Tourney font-bold'>
            Web Development
          </h4>
          <p className='text-2xl w-11/12 p-3  font-Dongle font-normal leading-6'>
            Having a solid foundation of web development basics and the ability
            to build an elegant responsive website from scratch and write easy
            to understant code.
          </p>
          <div className='w-full min-h-5 h-5 bg-accent mt-auto rounded-b-lg'></div>
        </div>

        <div
          data-aos='fade-up-right'
          data-aos-delay='150'
          data-aos-once='true'
          data-aos-duration='1000'
          className=' flex flex-col justify-center items-center w-96  md:w-5/12 max-w-sm  lg:w-1/3 mb-6 rounded-xl   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005] border border-dotted border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/zpxybbhl.json'
              trigger='hover'
              style={{ width: '125px', height: '125px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-6 text-xl font-Tourney font-bold'>
            Web Development
          </h4>
          <p className='text-2xl w-11/12 p-3  font-Dongle font-normal leading-6'>
            Having a solid foundation of web development basics and the ability
            to build an elegant responsive website from scratch and write easy
            to understant code.
          </p>
          <div className='w-full min-h-5 h-5 bg-accent mt-auto rounded-b-lg'></div>
        </div>
        <div
          data-aos='fade-in'
          data-aos-delay='150'
          data-aos-once='true'
          data-aos-duration='1000'
          className=' flex flex-col justify-center items-center w-96  md:w-5/12 max-w-sm  lg:w-1/3 mb-6 rounded-xl   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005] border border-dotted border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/zpxybbhl.json'
              trigger='hover'
              style={{ width: '125px', height: '125px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-6 text-xl font-Tourney font-bold'>
            Web Development
          </h4>
          <p className='text-2xl w-11/12 p-3  font-Dongle font-normal leading-6'>
            Having a solid foundation of web development basics and the ability
            to build an elegant responsive website from scratch and write easy
            to understant code.
          </p>
          <div className='w-full min-h-5 h-5 bg-accent mt-auto rounded-b-lg'></div>
        </div>
      </div>
    </motion.section>
  );
};

export default Skills;
