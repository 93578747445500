//  icons
import {
  FiYoutube,
  FiInstagram,
  FiDribbble,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
  FiFacebook,
} from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';

// companies icons

// projects images
import Oil from './assets/img/projects/oil.jpeg';
import Flour from './assets/img/projects/flour.jpeg';

import GasImage from './assets/img/projects/gas.png';
import RealEstateImage from './assets/img/projects/real estate (1).png';
import AppleVinegarImage from './assets/img/projects/apple vinegar.png';
import LaboratoryEquipment from './assets/img/projects/lab eqipments.png';
import Drilling from './assets/img/projects/drilling processes.png';
import JewelleryImage from './assets/img/projects/jewellery.png';
import OrganicFood from './assets/img/projects/organic food (1).png';
import UltraSound from './assets/img/projects/ultrasound.png';
import OilTools from './assets/img/projects/oil tools.png';

// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg5 from './assets/img/skills/nextjs.png';
import SkillImg6 from './assets/img/skills/nodejs.png';
import SkillImg7 from './assets/img/skills/git.png';
import SkillImg8 from './assets/img/skills/figma.png';

// testimonial images
import AbdulsalamPhoto from './assets/img/saloom avatar.png';
import abdulmajeedPhoto from './assets/img/markatiq logo (full).svg';
import OmarPhoto from './assets/img/omar avatar.png';

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  // {
  //   name: 'products',
  //   href: 'portfolio',
  // },
  // {
  //   name: 'services',
  //   href: 'services',
  // },
  {
    name: 'founders',
    href: 'testimonials',
  },
  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  // {
  //   icon: <FiYoutube />,
  //   href: '',
  // },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/markatiq_agency/',
  },
  // {
  //   icon: <FiFacebook />,
  //   href: '',
  // },
  {
    icon: <BsWhatsapp />,
    href: 'https://wa.me/9647728887281',
  },
];

// companies

export const brands = [
  {
    img: 'https://cdn.lordicon.com/zpxybbhl.json',
    service: 'Marketing Consultations',
    href: '',
  },
  {
    img: 'https://cdn.lordicon.com/puvaffet.json',
    service: 'Content Creation',
    href: '',
  },
  {
    img: 'https://cdn.lordicon.com/gqdnbnwt.json',
    service: 'Facebook, Instagram Ads',
    href: '',
  },

  {
    img: 'https://cdn.lordicon.com/vixtkkbk.json',
    service: 'Photography',
    href: '',
  },
  {
    img: 'https://cdn.lordicon.com/hbbeevsh.json',
    service: 'Digital Brand Growth',
    href: '',
  },
  {
    img: 'https://cdn.lordicon.com/gqzfzudq.json',
    service: 'Website Creation & Development',
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Oil,
    name: 'Oil',
    category: 'Energy',
  },
  {
    id: '2',
    image: AppleVinegarImage,
    name: 'Apple_Vinegar',
    category: 'Food',
  },
  {
    id: '3',
    image: OrganicFood,
    name: 'Organic_Products',
    category: 'Food',
  },
  {
    id: '4',
    image: Flour,
    name: 'Flour',
    category: 'Food',
  },
  {
    id: '5',
    image: LaboratoryEquipment,
    name: 'Laboratory_Equipment',
    category: 'Medical',
  },
  {
    id: '6',
    image: UltraSound,
    name: 'Ultra_Sound_Machines',
    category: 'Medical',
  },
  {
    id: '7',
    image: GasImage,
    name: 'Gas',
    category: 'Energy',
  },
  {
    id: '8',
    image: OilTools,
    name: 'Oil_Tools',
    category: 'Energy',
  },
  {
    id: '9',
    image: Drilling,
    name: 'Drilling_Processes_Services',
    category: 'Energy',
  },
  {
    id: '10',
    image: JewelleryImage,
    name: 'Gold,_Diamonds_and_Valuable_Stones_Mining_and_Shipping',
    category: 'Jewellery',
  },
  {
    id: '11',
    image: RealEstateImage,
    name: 'Real_Estate_Investments_and_Development',
    category: 'Real Estate',
  },
];

// projects
export const projectsNav = [
  {
    name: 'All',
  },
  {
    name: 'Energy',
  },
  {
    name: 'Food',
  },
  {
    name: 'Medical',
  },
  {
    name: 'Jewellery',
  },
  {
    name: 'Real Estate',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: <FiLayout />,
    name: 'Web Design',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiSettings />,
    name: 'Web Development',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiPenTool />,
    name: 'Branding',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiTag />,
    name: 'SEO',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: AbdulsalamPhoto,
    authorText: 'salamhamid931@gmail.com',
    authorPhone: '+964 772 888 72 81',
    authorName: 'Abdulsalam Kutaibani',
    authorPosition: 'Founder',
  },
  {
    authorImg: OmarPhoto,
    authorText: 'Omarmohamedli620@gmail.com',
    authorPhone: '+964 770 614 13 90',
    authorName: 'Omar Luhaiby',
    authorPosition: 'Co-Founder',
  },
  {
    authorImg: abdulmajeedPhoto,
    authorText: 'realabdulmajeedk@gmail.com',
    authorPhone: '+90 552 726 44 03',
    authorName: 'Abdulmajeed Kutaibani',
    authorPosition: 'Co-Founder',
  },
  // {
  //   authorImg: AvatarImage,
  //   authorText:
  //     'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
  //   authorName: 'John Doe',
  //   authorPosition: 'Founder, Diamonds Group',
  // },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have_a_question?',
    subtitle: `We're_here_to_help_you.`,
    description: 'Markatiq.group@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Current_Location',
    subtitle: 'Tikrit - Slah Al Deen - Iraq',
    description: '',
  },
];
