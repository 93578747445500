import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

// import img
import Image from '../assets/img/markatiq logo (color).svg';

const About = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const handleClick = () => {
    return document.getElementById('contact').scrollIntoView();
  };

  return (
    <section className='section bg-secondary overflow-hidden' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row lg:gap-24 gap-10 items-center justify-center'>
          <div className='w-full flex justify-center transform scale-150'>
            <motion.img
              initial={{ opacity: 0, scale: 0.5 }}
              transition={{
                default: {
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
                },
                scale: {
                  type: 'spring',
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              className='object-cover lg:w-60 lg:h-60 w-32 h-32 lg:ml-0 ml-3 md:mx-auto lg:mx-0 rounded-2xl'
              src={Image}
              alt=''
            />
          </div>
          <motion.div
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1.2 }}
            viewport={{ once: true }}
            className='flex flex-col items-center text-center lg:items-start lg:text-center'
          >
            <div className='flex flex-coltext-center lg:text-left'>
              {/* <p className='mb-4 text-accent'>
                Freelance Frontend Web Developer
              </p> */}

              <p
                className={`lg:w-full  mb-8 mt-8 lg:text-xl text-base ${
                  currentLanguageCode === 'ar'
                    ? 'md:text-right'
                    : 'md:text-left'
                }`}
              >
                {t('aboutUs1')} <br />
                {t('aboutUs2')}
              </p>
            </div>
            <button
              className={`lg:flex hidden btn btn-md bg-accent hover:bg-green-100 hover:text-black rounded hover:border-green-100 hover:border-4 transition-all ${
                currentLanguageCode === 'ar' ? 'lg:ml-auto' : ''
              }`}
              onClick={handleClick}
            >
              {t('contact_us')}
            </button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
