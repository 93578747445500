import React, { useState, useEffect } from 'react';

import { projectsData } from '../data';
import { projectsNav } from '../data';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';
import {
  UilInstagram,
  UilFacebookF,
  UilWhatsapp,
} from '@iconscout/react-unicons';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
AOS.init();

// import components
// import Project from './Project';

const Projects = () => {
  defineElement(lottie.loadAnimation);

  const { t } = useTranslation();

  const [item, setItem] = useState({ name: 'all' });
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    // get projects based on item
    if (item.name === 'all') {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({ name: e.target.textContent.toLowerCase() });
    setActive(index);
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      id='skills'
      className=' lg:px-12 w-full lg:pb-24 min-h-screen relative overflow-hidden lg:mb-0 pb-36'
    >
      <div className=' lg:px-5 px-3  lg:py-10 py-5 mx-auto '></div>
      {/* //////////////////////////////////////// */}

      <div className='flex flex-wrap   lg:justify-evenly justify-around items-center  mx-auto lg:w-full'>
        <div
          data-aos='fade-up-left'
          data-aos-delay='150'
          data-aos-duration='1000'
          data-aos-once='true'
          className=' flex flex-col justify-center items-center  max-w-sm h-[350px]  lg:w-1/3 h-96 mb-6 rounded-lg   m-2 skill-post text-center  text-black  bg-opacity-[0.005] border-dashed border-t-2 border-l-2 border-r-2 border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/zpxybbhl.json'
              trigger='hover'
              style={{ width: '100px', height: '100px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-4 text-xl font-Tourney font-bold'>
            {t('marketing_consultations')}
          </h4>
          <p className=' text-md  p-3  font-Dongle font-normal leading-6'>
            {t('marketing_consultations_description')}
          </p>
          <div className='w-full min-h-2 h-2 bg-accent mt-auto rounded-b-lg'></div>
        </div>
        <div
          data-aos='fade-up'
          data-aos-duration='1000'
          data-aos-once='true'
          data-aos-delay='400'
          className=' flex flex-col justify-center items-center  max-w-sm h-[350px]  lg:w-1/3 mb-6 rounded-lg   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005]  border-dashed border-t-2 border-l-2 border-r-2 border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/gqdnbnwt.json'
              trigger='hover'
              style={{ width: '100px', height: '100px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-4 text-xl font-Tourney font-bold'>{t('ads')}</h4>
          <p className='text-md  p-3  font-Dongle font-normal leading-6'>
            {t('ads_description')}
          </p>
          <div className='w-full min-h-2 h-2 bg-accent mt-auto rounded-b-lg'></div>
        </div>

        <div
          data-aos='fade-up-right'
          data-aos-delay='150'
          data-aos-once='true'
          data-aos-duration='1000'
          className=' flex flex-col justify-center items-center  max-w-sm h-[350px]  lg:w-1/3 mb-6 rounded-lg   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005]  border-dashed border-t-2 border-l-2 border-r-2 border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-1 '>
            <lord-icon
              src='https://cdn.lordicon.com/hbbeevsh.json'
              trigger='hover'
              style={{ width: '115px', height: '115px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-4 text-xl font-Tourney font-bold'>
            {t('digital_growth')}
          </h4>
          <p className='text-md  p-3  font-Dongle font-normal leading-6'>
            {t('digital_growth_description')}
          </p>
          <div className='w-full min-h-2 h-2 bg-accent mt-auto rounded-b-lg'></div>
        </div>
        <div
          data-aos='fade-in'
          data-aos-delay='150'
          data-aos-once='true'
          data-aos-duration='1000'
          className=' flex flex-col justify-center items-center  max-w-sm h-[350px]  lg:w-1/3 mb-6 rounded-lg   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005]  border-dashed border-t-2 border-l-2 border-r-2 border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/gqzfzudq.json'
              trigger='hover'
              style={{ width: '100px', height: '100px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-4 text-xl font-Tourney font-bold'>{t('web')}</h4>
          <p className='text-md  p-3  font-Dongle font-normal leading-6'>
            {t('web_description')}
          </p>
          <div className='w-full min-h-2 h-2 bg-accent mt-auto rounded-b-lg'></div>
        </div>
        <div
          data-aos='fade-up-right'
          data-aos-delay='150'
          data-aos-once='true'
          data-aos-duration='1000'
          className=' flex flex-col justify-center items-center  max-w-sm h-[350px]  lg:w-1/3 mb-6 rounded-lg   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005]  border-dashed border-t-2 border-l-2 border-r-2 border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-8 mb-4'>
            <UilInstagram className='w-[65px] h-[65px] text-accent' />
            <UilFacebookF className='w-[65px] h-[65px] text-accent' />
            <UilWhatsapp className='w-[65px] h-[65px] text-accent' />
          </div>
          <h4 className='  mt-4 text-xl font-Tourney font-bold'>
            {t('social')}
          </h4>
          <p className='text-md  p-3  font-Dongle font-normal leading-6'>
            {t('social_description')}
          </p>
          <div className='w-full min-h-2 h-2 bg-accent mt-auto rounded-b-lg'></div>
        </div>
        <div
          data-aos='fade-in'
          data-aos-delay='150'
          data-aos-once='true'
          data-aos-duration='1000'
          className=' flex flex-col justify-center items-center  max-w-sm h-[350px]  lg:w-1/3 mb-6 rounded-lg   m-2 skill-post text-center  text-black bg-accent bg-opacity-[0.005]  border-dashed border-t-2 border-l-2 border-r-2 border-accent'
        >
          <div className=' flex justify-center items-center rounded-lg bg-opacity-50 mt-4'>
            <lord-icon
              src='https://cdn.lordicon.com/vixtkkbk.json'
              trigger='hover'
              style={{ width: '100px', height: '100px' }}
            ></lord-icon>
          </div>
          <h4 className='  mt-4 text-xl font-Tourney font-bold'>
            {t('photography')}
          </h4>
          <p className='text-md  p-3  font-Dongle font-normal leading-6'>
            {t('photography_description')}
          </p>
          <div className='w-full min-h-2 h-2 bg-accent mt-auto rounded-b-lg'></div>
        </div>
      </div>
    </motion.section>

    //   return (
    //     <div className=' w-1/2' key={item.id}>
    //       <img src='' alt='' />
    //       <span>Content Creation</span>
    //       <p>
    //         We are content creators. Our services include promoting, making
    //         product videos, advertising services etc. We are the best service
    //         in this city, so if you want to create content, contact us.
    //       </p>
    //     </div>
    //   );
    // })}
    // {/* projects nav */}
    //  // {/*<nav className='mb-12 max-w-xl mx-auto '>
    //     <ul className='flex flex-wrap md:flex-nowrap gap-8 justify-evenly items-center text-black'>
    //       {projectsNav.map((item, index) => {
    //         return (
    //           <li
    //             onClick={(e) => {
    //               handleClick(e, index);
    //             }}
    //             className={`${
    //               active === index ? 'active' : ''
    //             } cursor-pointer capitalize m-4 hover:text-green-500 border p-2 border-green-500 rounded whitespace-nowrap`}
    //             key={index}
    //           >
    //             {item.name}
    //           </li>
    //         );
    //       })}
    //     </ul>
    // </nav>*/}
    // {/* projects */}
    // {/*<section className='w-full flex flex-wrap justify-center items-center gap-5 gap-y-12 lg:grid-cols-2  lg:gap-y-8'>
    //   {projects.map((item) => {
    //     return <Project item={item} key={item.id} />;
    //   })}
    // </section>*/}
  );
};

export default Projects;
