import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

// import woman image
import heroImage from '../assets/img/markatiq logo (full).svg';
import cookies from 'js-cookie';

const Hero = () => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const handleClick = () => {
    return document.getElementById('contact').scrollIntoView();
  };

  return (
    <section
      id='home'
      className='lg:h-screen max-h-[1200px] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat pt-32 pb-20 lg:py-0 overflow-hidden'
    >
      <div className='container mx-auto h-full'>
        <div className='flex lg:flex-row flex-col-reverse items-center h-full pt-8'>
          <div className='flex-1 flex flex-col items-center lg:items-start text-center lg:text-left gap-12'>
            <h1
              className={` text-xl leading-[40px]  lg:leading-[50px] lg:text-2xl  font-bold  ${
                currentLanguageCode === 'ar' ? 'md:text-right' : 'md:text-left'
              }`}
            >
              {t(`hero1`)}
              <br />
              {t(`hero2`)}
            </h1>
            {/* <p className='pt-4 pb-8 md:pt-6 md:pb-12 max-w-[480px] text-lg text-center lg:text-left'>
              Lorem ipsum dolor sit amet consectetur adipisicing illo ad labore
              dolor elit.
            </p> */}
            <button
              onClick={handleClick}
              className={`btn btn-md bg-accent hover:bg-green-100 hover:text-black rounded hover:border-green-100 hover:border-4 md:btn-lg transition-all ${
                currentLanguageCode === 'ar' ? 'md:ml-auto' : ''
              }`}
            >
              {t('work_with_us')}
            </button>
          </div>
          <div className='lg:flex flex-1 lg:justify-end lg:items-end '>
            <motion.img
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              src={heroImage}
              alt=''
              className='h-full w-full lg:scale-100 scale-125 transform  lg:mb-12'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
