import React from 'react';

// import icons
import { social } from '../data';

const Socials = ({ navTextColor }) => {
  return (
    <ul className='flex space-x-6 '>
      {social.map((item, index) => {
        return (
          <li
            className={`flex justify-center items-center ${navTextColor}`}
            key={index}
          >
            <a
              className='lg:text-3xl text-2xl hover:text-accent'
              href={item.href}
            >
              {item.icon}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Socials;
