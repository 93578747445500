import React, { useEffect, useState } from 'react';

// import components
import Logo from '../assets/img/markatiq logo (color).svg';
import LogoLight from '../assets/img/markatiq logo (white).svg';
import Nav from '../components/Nav';
import NavMobile from '../components/NavMobile';
import Socials from './Socials';
import { BsCurrencyExchange } from 'react-icons/bs';
import DropdownMenuForLanguages from './DropdownMenu';

const Header = () => {
  const [bg, setBg] = useState(false);
  const [navTextColor, setNavTextColor] = useState('text-black');
  const [logoColor, setLogoColor] = useState(Logo);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        setBg(true);
        setNavTextColor('text-white');
        setLogoColor(LogoLight);
      } else {
        setBg(false);
        setNavTextColor('text-black');
        setLogoColor(Logo);
      }
    });
  });
  const handleWidgetButton = () => {
    return (
      document
        .getElementById('currency-widget')
        .classList.toggle('right-[-20rem]'),
      document
        .getElementById('currency-widget')
        .classList.toggle('right-[2.75rem]')
    );
  };

  return (
    <header
      className={`${
        bg ? `bg-black h-16 ` : 'h-28'
      } flex items-center fixed top-0 w-full text-white z-10 transition-all duration-300`}
    >
      <div
        className={`container mx-auto h-full flex items-center justify-between`}
      >
        {/* logo */}
        <a href='/' className='lg:w-12 w-12'>
          <img src={logoColor} alt='' />
        </a>
        {/* nav */}
        <div className='hidden lg:block '>
          <Nav navTextColor={navTextColor} />
        </div>
        {/* Socials */}

        <div className='flex justify-center gap-8 items-center'>
          <div className='lg:block hidden'>
            <Socials navTextColor={navTextColor} />
          </div>
          <DropdownMenuForLanguages navTextColor={navTextColor} />
          <div
            id='currency-widget'
            className='absolute w-[250px] right-[-20rem] top-[6rem] text-xl border-2 rounded-lg tranform: transition-all duration-300'
          >
            <fxwidget-er
              inverse='false'
              amount='100'
              decimals='2'
              large='true'
              shadow='true'
              symbol='true'
              flag='true'
              changes='true'
              grouping='true'
              border='true'
              main-curr='USD'
              sel-curr='TRY,EUR,GBP,AED,KWD,SAR'
              background-color='#fafafa'
              border-radius='0.5'
            ></fxwidget-er>
          </div>
          <button
            onClick={handleWidgetButton}
            className={` lg:w-10 lg:h-10 w-[29px] h-[29px]  rounded-xl flex justify-center items-center ${navTextColor} text-4xl hover:text-accent-hover`}
          >
            <BsCurrencyExchange />
          </button>
        </div>
        {/* nav mobile*/}
        <div className='lg:hidden'>
          <NavMobile />
        </div>
      </div>
    </header>
  );
};

export default Header;
