import React, { useState, useEffect } from 'react';

// import link
import { Link, animateScroll as scroll } from 'react-scroll';
// import icon
import { ChevronUpIcon } from '@heroicons/react/outline';

const BackTopBtn = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      return window.scrollY > 600 ? setShow(true) : setShow(false);
    });
  });
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  {
    return (
      show && (
        <button
          onClick={() => scrollToTop()}
          className='bg-accent hover:bg-accent-hover text-white lg:w-12 lg:h-12 w-8 h-8 rounded-full fixed lg:right-12 right-8 lg:bottom-12 bottom-8 cursor-pointer flex justify-center items-center transition-all'
        >
          <ChevronUpIcon className='lg:w-6 lg:h-6 w-4 h-4' />
        </button>
      )
    );
  }
};

export default BackTopBtn;
